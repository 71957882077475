import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import "react-multi-carousel/lib/styles.css";
import "./Style.css";
import Carousel from "react-multi-carousel";

const defaultarticles = [
  {
    category: "Awards",
    date: "2024",
    title: "Inc. 5000",
    description:
      "Vestian Named to Inc. 5000 List of Fastest-Growing Private Companies in America for 2024",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46d896034e3d81c4276a0_Retail_Pop-Up.avif",
  },
  {
    category: "Awards",
    date: "2024",
    title: "MUSE Design Award",
    description: "Interior Design — Commercial Category",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f469239887a765a5d033a2_Office_image%2015.avif",
  },
  {
    category: "Awards",
    date: "2023",
    title: "Frost & Sullivan Project Evaluation & Recognition Program",
    description: "First Runner-Up in Customer Value Leadership Award",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46a8a2c7b9e5fa7c29d2f_Retail_image_4.avif",
  },
  {
    category: "Awards",
    date: "2023",
    title: "MUSE Design Award",
    description: "Interior Design — Commercial Category",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46a8a2c7b9e5fa7c29d2f_Retail_image_4.avif",
  },
  {
    category: "Awards",
    date: "2022",
    title: "Frost & Sullivan Project Evaluation & Recognition Program",
    description: "First Runner-Up in Customer Value Leadership Award",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46a8a2c7b9e5fa7c29d2f_Retail_image_4.avif",
  },
  {
    category: "Awards",
    date: "2021",
    title: "India PMO of the Year",
    description: "PMO India Awards Forum 2021",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46a8a2c7b9e5fa7c29d2f_Retail_image_4.avif",
  },
  {
    category: "Awards",
    date: "2021",
    title: "Frost & Sullivan Project Evaluation & Recognition Program",
    description: "Won the Customer Value Leadership Award",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46a8a2c7b9e5fa7c29d2f_Retail_image_4.avif",
  },
  {
    category: "Awards",
    date: "2020",
    title: "MUSE Design Award",
    description: "Interior Design — Commercial Category",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46a8a2c7b9e5fa7c29d2f_Retail_image_4.avif",
  },
  {
    category: "Awards",
    date: "2021",
    title: "The Economic Times Best Workplaces for Women",
    description:
      "Vestian has been awarded 'The Economic Times Best Workplaces for Women 2021.'",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/ 66f46a8a2c7b9e5fa7c29d2f_Retail_image_4.avif",
  },
  {
    category: "Awards",
    date: "2020",
    title: "Estrade Awards",
    description: "Office Interior National Category",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46a8a2c7b9e5fa7c29d2f_Retail_image_4.avif",
  },
  {
    category: "Awards",
    date: "2020",
    title: "Frost & Sullivan Project Evaluation & Recognition Program",
    description: "First Runner-Up in Customer Value Leadership Award",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46a8a2c7b9e5fa7c29d2f_Retail_image_4.avif",
  },
];

const Award = ({ articles = defaultarticles }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const CustomButtonGroup = ({ next, previous }) => {
    return (
      <div className="Award-buttons position-absolute bottom-0 start-0 d-flex gap-2 mb-4">
        <button
          className="Award-button"
          onClick={previous}
          aria-label="Previous slide"
        >
          <BsArrowLeft size={20} />
        </button>
        <button className="Award-button" onClick={next} aria-label="Next slide">
          <BsArrowRight size={20} />
        </button>
      </div>
    );
  };
  return (
    <div
      className="py-5"
      style={{
        backgroundColor: "#EFF0F2",
      }}
    >
      <Container className="Award-container py-5 position-relative">
        <div className="d-flex justify-content-between align-items-start mb-5">
          <h2 className="Award-title">
            We provide award-winning
            <br /> services.
          </h2>
        </div>
        <Carousel
          responsive={responsive}
          infinite={true}
          customButtonGroup={<CustomButtonGroup />}
          arrows={false}
          renderButtonGroupOutside={true}
          className="Award-wrapper"
        >
          {articles.map((article, index) => (
            <Container key={index}>
              <Row className="justify-content-start">
                <Col
                  className="bg-white rounded-4 mx-2 p-5 mb-5"
                  style={{ height: "430px", width: "300px",textAlign:'left' }}
                >
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src="https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/665dcd9a76a8bf7378172074_laurels.svg"
                      alt="Laurels Icon"
                      style={{ height: "60px", marginBottom: "20px",alignContent:"flex-start",justifyContent:'left' }}
                      className="p-2"
                    />
                    <h3 className="text-secondary">{article.date}</h3>
                    <h2>{article.title}</h2>
                    <p className="text-muted">{article.description}</p>
                    <Button
                      variant="link"
                      className="text-decoration-none p-0 text-black text-left"
                      style={{textAlign:'left'}}
                    >
                      Press Release <BsArrowRight />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default Award;
