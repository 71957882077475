import React from 'react'
import {  Col, Container, Row } from 'react-bootstrap'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import 'react-multi-carousel/lib/styles.css'
import "../style.css"
import Carousel from 'react-multi-carousel'
const defaultarticles = [
    {
      featured: true,
      category: "INSIGHTS",
      title: "Pop-Up Stores & Short-Term Leases",
      description: "Pop-up stores and short-term leases are increasingly becoming a flexible and strategic solution for modern retailers.",
      image: "/placeholder.svg?height=600&width=800",
    },
    {
      category: "INSIGHTS",
      date: "1 MAY",
      title: "Understanding Office Lease Flexibility",
      description: "Lease flexibility has emerged as a strategic imperative for businesses, enabling adaptable office spaces, cost-effective management, and agile navigation of economic uncertainties...",
      image: "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46d896034e3d81c4276a0_Retail_Pop-Up.avif",
    },
    {
      category: "NEWS",
      date: "6 MAR",
      title: "Transforming Office Spaces for the Hybrid Workforce",
      description: "Businesses can strategically transform their office spaces to support hybrid work models by implementing flexible designs, integrating advanced technology, fostering a collaborative...",
      image: "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f469239887a765a5d033a2_Office_image%2015.avif",
    },
    {
      category: "INSIGHTS",
      date: "15 FEB",
      title: "Trends Shaping Modern Workplaces",
      description: "Optimizing office environments through innovative design and technology to promote collaboration and productivity in the evolving workplace.",
      image: "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46a8a2c7b9e5fa7c29d2f_Retail_image_4.avif",
    }
  ]
const RelatedArticle = ({ articles = defaultarticles }) => {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1
        }
      }
    
      const CustomButtonGroup = ({ next, previous }) => {
        return (
          <div className="PortfolioCarousel-buttons position-absolute bottom-0 start-0 d-flex gap-2 mb-4">
            <button 
              className="PortfolioCarousel-button" 
              onClick={previous}
              aria-label="Previous slide"
            >
              <BsArrowLeft size={20} />
            </button>
            <button 
              className="PortfolioCarousel-button" 
              onClick={next}
              aria-label="Next slide"
            >
              <BsArrowRight size={20} />
            </button>
          </div>
        )
      }
  return (
    <Container className="PortfolioCarousel-container py-5 position-relative">
      <h2 className="PortfolioCarousel-title display-4 mb-5">Related Articles</h2>
      <Row className="PortfolioSolutions-featured mb-5">
          <Col md={6} className="mb-4 mb-md-0">
            <div className="PortfolioSolutions-featured-image" style={{ height: '400px' }}>
              <img
                src="https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46d896034e3d81c4276a0_Retail_Pop-Up.avif"
                alt={articles[0].title}
                className="w-100 h-100 object-fit-cover rounded-3"
              />
            </div>
          </Col>
          <Col md={6} className="d-flex flex-column justify-content-center">
            <div className='d-flex'>
              <div className="PortfolioSolutions-badge mb-4 my-auto" style={{ backgroundColor: '#0000FF', color: 'white', padding: '7px', display: 'inline-block', fontSize: '14px' }}>
                {/* {articles[0].category} */}
              </div>
              <div className="PortfolioSolutions-badge mb-4 my-auto" style={{ padding: '4px 12px', display: 'inline-block', borderRadius: '4px', fontSize: '14px' }}>
                {articles[0].category}
              </div>
            </div>
            <h3 className="PortfolioSolutions-featured-title h1 mb-4">{articles[0].title}</h3>
            <p className="PortfolioSolutions-description text-muted mb-4">{articles[0].description}</p>
            <button className="PortfolioSolutions-read-more btn btn-link p-0 text-dark text-decoration-none d-flex align-items-center gap-2">
              Read More
              <BsArrowRight />
            </button>
          </Col>
        </Row>
      
      <Carousel
        responsive={responsive}
        infinite={true}
        customButtonGroup={<CustomButtonGroup />}
        arrows={false}
        renderButtonGroupOutside={true}
        className="PortfolioCarousel-wrapper"
      >
        {articles.map((article, index) => (
          <div key={index} className="PortfolioCarousel-item px-3">
            <div className="PortfolioCarousel-image-wrapper mb-4">
              <img
                src={article.image}
                alt={article.title}
                className="PortfolioCarousel-image w-100 h-100 object-fit-cover rounded-3"
              />
            </div>
            <div className="PortfolioCarousel-content">
              <div className="d-flex align-items-center gap-3 mb-3">
                <div style={{ width: "14px", height: "14px",backgroundColor:"#0000FF" }}></div>
                <span className="PortfolioCarousel-badge">
                  {article.category}
                </span>
                <span className="PortfolioCarousel-date">
                  {article.date}
                </span>
              </div>
              <h3 className="PortfolioCarousel-item-title h5 mb-3">
                {article.title}
              </h3>
              <p className="PortfolioCarousel-description text-muted">
                {article.description}
              </p>
            </div>
          </div>
        ))}
      </Carousel>

    </Container>
  )
}

export default RelatedArticle