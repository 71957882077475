import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./Style.css";

const CompanySectionone = () => {
    return (
        <div style={{ backgroundColor: "#06151E", color: "white", padding: "50px 0" }}>
            <Container fluid>
                <Row className="align-items-center flex flex-md-row flex-column-reverse">
                    <Col style={{
                        aspectRatio: "1/1",
                    }} md={6} className="CompanySectiononeimg-section position-relative" >
                        <div className="w-100">
                            <img className="CompanySectiononeimg"
                                src="https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/665dae60e71d5c435e7291c4_about_img-hero-map%402x.webp"
                                alt="Map"
                                style={{ width: "100%", borderRadius: "15px" }}
                            />
                        </div>
                    </Col>

                    <Col md={6} className="CompanySectiononecontent-section2 px-3">
                        <h2 className="CompanySectiononeheading">Worldwide Reach,<br /> Local Insight.</h2>
                        <p className="CompanySectiononetext">
                            Vestian combines global expertise with local knowledge to deliver innovative corporate real estate solutions. Our worldwide network of professionals provides personalized strategies that transform real estate challenges into business advantages. From emerging startups to Fortune 500 companies, turning singular projects into lasting partnerships.
                        </p>
                        <Row className=" VestianSection-border">
                            <Col md={4} className="text-left">
                                <div className=" p-3 border-top border-2">
                                    <h2 className="CompanySectiononeheading2">30</h2>
                                    <p className="CompanySectiononetext">Offices</p>
                                </div>
                            </Col>
                            <Col md={4} className="text-left">
                                <div className=" p-3 border-top border-2">
                                    <h2 className="CompanySectiononeheading2">1200</h2>
                                    <p className="CompanySectiononetext">Professionals</p>
                                </div>
                            </Col>
                            <Col md={4} className="text-left">
                                <div className=" p-3 border-top border-2">
                                    <h2 className="CompanySectiononeheading2">2500+</h2>
                                    <p className="CompanySectiononetext">Completed Projects</p>
                                </div>
                            </Col>
                        </Row>
                        <div className="mt-4">
                            <Button variant="primary" className="rounded-1" size="lg">Find an Office Near You</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CompanySectionone;
