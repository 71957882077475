// File: ThirdCompnent.jsx
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
  const defaultsections = [
    {
      title: "Portfolio Strategy & Optimization",
      description:
        "Maximize the value of your industrial real estate portfolio. We align your network of facilities with your business objectives, optimizing space utilization, reducing costs, and supporting your evolving distribution and production needs across multiple locations.",
    },
    {
      title: "Site Selection & Feasibility",
      description:
        "Identify strategic locations for your industrial facilities. We analyze crucial factors such as transportation networks, labor markets, and zoning regulations to find optimal sites that enhance your supply chain efficiency and operational performance.",
    },
    {
      title: "Transaction Management",
      description:
        "Navigate complex industrial real estate transactions with confidence. Whether you're expanding your distribution network or consolidating operations, we provide market insights and strong negotiation support to secure flexible agreements that put your business interests first.",
    },
    {
      title: "Transaction Management",
      description:
        "Navigate complex industrial real estate transactions with confidence. Whether you're expanding your distribution network or consolidating operations, we provide market insights and strong negotiation support to secure flexible agreements that put your business interests first.",
    },
  ];

const ThirdCompnent = ({ sections = defaultsections, heading }) => {
  // Data Array for Repeated Content

  return (
    <Container className="my-5">
      {/* Main Heading */}
      <Row className="mb-4">
        <Col md={6}>
          <h1 className="display-4">
            {heading ||
              "Industrial Real Estate Solutions Tailored to Your Business Goals"}
          </h1>
        </Col>
        <Col md={6}>
          <Container fluid>
            {sections.map((section, index) => (
              <Row key={index} className="mb-5">
                <Col md={8}>
                  <h3>{section.title}</h3>
                  <p>{section.details}</p>
                </Col>
                <Col md={4} className="d-flex align-items-center">
                  <Link
                    href="#"
                    className="text-decoration-none fw-semibold text-dark"
                  >
                    Learn more <FiArrowRight />
                  </Link>
                </Col>
              </Row>
            ))}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default ThirdCompnent;
