import React, { useCallback, useEffect, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import "./MainNavBar.css";
import DropdownIcon from "../DropdownIcon/DropdownIcon";

const services = [
  { text: "Portfolio Solutions", link: "/services/portfoliosolutions" },
  { text: "Transaction Management", link: "/services/transaction" },
  { text: "Facility Management", link: "/services/facility" },
];
const industries = [
  { text: "Office", link: "/industries/office" },
  { text: "Retail", link: "/industries/retail" },
  { text: "Healthcare", link: "/industries/healthcare" },
  { text: "Industrial", link: "/industries/industrial" },
];

const MainNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [showNavBar, setShowNavBar] = useState(true);
  const [scrollY, setScrollY] = useState(0);
  const [hoveredDropdown, setHoveredDropdown] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const handleMouseEnter = (dropdown) => {
    setHoveredDropdown(dropdown);
    setHoveredItem(dropdown);
  };

  const handleMouseLeave = () => {
    setHoveredDropdown(null);
    setHoveredItem(null);
  };

  const handleNavClick = (e, path) => {
    e.preventDefault();
    setExpanded(false);
    navigate(path);
  };

  const controlScroll = useCallback(() => {
    if (window.scrollY > scrollY) {
      setShowNavBar(false);
      setHoveredDropdown(null);
    } else {
      setShowNavBar(true);
    }
    setScrollY(window.scrollY);
  }, [scrollY]);

  useEffect(() => {
    window.addEventListener("scroll", controlScroll);
    return () => {
      window.removeEventListener("scroll", controlScroll);
    };
  }, [controlScroll]);

  const DropdownComp = ({ data, text, link }) => {
    return (
      <div
        onMouseLeave={handleMouseLeave}
        className="w-100 top-100 bg-white position-absolute p-3 shadow"
      >
        <Container>
          <div
            style={{
              top: "100%",
            }}
            className="naaaavdropdownbox"
          >
            {data.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                className="naaaavdropdown-item2 p-3"
              >
                <div className="naaaavdropdown-item p-2">{item?.text}</div>
              </Link>
            ))}
            <Link to={link} className="naaaavdropdown-item2 p-3">
              <div className="mt-2 ms-2 d-flex justify-content-start align-items-center">
                View All {text} <FaArrowRight className="ms-2" />
              </div>
            </Link>
          </div>
        </Container>
      </div>
    );
  };

  return (
    <div>
      <Navbar
        expand="sm"
        className={`mainnnContainerNavComponent ${showNavBar ? "show" : "hide"
          } ${showNavBar ? "blur-background" : ""} ${hoveredDropdown ? "bg-white" : ""
          }`}
        expanded={expanded}
        onToggle={(expanded) => setExpanded(expanded)}
      >
        <Container className="position-relative">
          <Navbar.Brand href="/">
            <img
              src="https://res.cloudinary.com/dbklt3v4d/image/upload/v1719306812/mailStone/bu7trgfdi4qyxyelonlc.png"
              alt=""
              className="MainnNavBarImage"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="mx-4 MainnNavBarButtons ">
              <div className="d-sm-flex align-items-center h-100 mx-auto d-none justify-content-center">
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => handleMouseEnter("services")}
                  className="naaaavdropdown mx-3 h-100 d-flex mt-2 justify-content-center align-items-center"
                >
                  Services
                  <DropdownIcon isHovered={hoveredItem === "services"} />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => handleMouseEnter("industries")}
                  className="naaaavdropdown mx-3 h-100 d-flex mt-2 justify-content-center align-items-center"
                >
                  Industries
                  <DropdownIcon isHovered={hoveredItem === "industries"} />
                </div>
              </div>
              <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                <Nav.Link
                  onClick={(e) => handleNavClick(e, "/company")}
                  href="/company"
                  className={location.pathname === "/projcompanyect" ? "active" : ""}
                >
                  Company
                </Nav.Link>
                <Nav.Link
                  onClick={(e) => handleNavClick(e, "/careers")}
                  href="/careers"
                  className={location.pathname === "/careers" ? "active" : ""}
                >
                  Careers
                </Nav.Link>
              </div>
            </Nav>
            <div className="mainnavbarhbsbuttoncontainer">
              <button onClick={() => navigate("/contactus")} className="mainnavBarButton m-auto">Contact Us</button>
            </div>
          </Navbar.Collapse>
        </Container>
        {hoveredDropdown && (
          <DropdownComp
            text={hoveredDropdown === "services" ? "services" : "industries"}
            link={hoveredDropdown === "services" ? "/services" : "/industries"}
            data={hoveredDropdown === "services" ? services : industries}
          />
        )}
      </Navbar>
    </div>
  );
};

export default MainNavBar;
