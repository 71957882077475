import React from "react";
import ServicesHeroSection from "../Component/ServicesHeroSection/ServicesHeroSection";
import AccordationComp from "../Component/AccordationComp/AccordationComp";
import SuccessStories from "../Component/SussessStories/SuccessStories";
import RelatedArticle from "../Component/RelatedArticle/RelatedArticle";

const Facility = () => {
  const AccordationData = [
    {
      header: "Maintenance & Engineering Services",
      text: "Maintain peak performance of your business properties. Our engineering and technical services ensure your facilities operate efficiently, safely, and sustainably, supporting your core business operations.",
      list: [
        "Preventive and reactive maintenance for corporate facilities.",
        "Energy management and optimization in business environments.",
        "Building systems audits and upgrades for company properties.",
        "Compliance and safety management in corporate spaces.",
        "Technology integration and smart building solutions for modern businesses.",
      ],
    },
    {
      header: "Integrated Facilities Management",
      text: "Our integrated facilities management services bring together people, places, and processes to provide a seamless approach to managing your properties, focusing on efficiency and productivity.",
      list: [
        "Comprehensive facilities management solutions.",
        "Space utilization and optimization strategies.",
        "Sustainability and green building practices.",
        "Vendor management and procurement optimization.",
        "Risk management and emergency planning.",
      ],
    },
    {
      header: "Workspace Planning & Delivery",
      text: "From workplace strategy to project delivery, we ensure that your workspaces are optimized for productivity and aligned with your business objectives, providing tailored solutions for your needs.",
      list: [
        "Workspace design and space planning.",
        "Project management for office fit-outs.",
        "Change management strategies.",
        "Technology integration in workspace solutions.",
        "Health and wellness initiatives for work environments.",
      ],
    },
    {
      header: "Performance Analytics & Benchmarking",
      text: "Gain actionable insights into your facilities’ performance with our advanced analytics and benchmarking tools, enabling smarter decisions and continuous improvement.",
      list: [
        "Energy and operational performance analytics.",
        "Custom KPI tracking and benchmarking.",
        "Data-driven decision-making tools.",
        "Occupancy and utilization analytics.",
        "Predictive maintenance and risk analysis.",
      ],
    },
  ];

  const accordationText = {
    leftText: "Leave the rest to us",
    rightText:
      "We want you to be able to focus on running your business, not worrying about the space you’re in. Our team handles day-to-day operations like maintenance, security, and space management through data-driven analytics that reveal areas for operational improvement and cost savings. We also partner on long-term strategic sourcing, vendor relations, and sustainability services like LEED certifications and the implementation of green technology.",
  };

  return (
    <div>
      <ServicesHeroSection
        Textinjsx={
          <>
            Facility
            <br />
            Management
          </>
        }
        img="https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c7ac24b7800d68fc50571c_Facility%20Management_BW.avif"
      />
      <AccordationComp
        leftText={accordationText.leftText}
        rightText={accordationText.rightText}
        AccordationContent={AccordationData}
      />
      <SuccessStories />
      <RelatedArticle />
    </div>
  );
};

export default Facility;
