import React from 'react'
import CompanySectionone from './CompanySectionone'
import VestianSection from './VestianSection'
import GlobalLeaderShip from './GlobalLeaderShip'
import Award from './Award'
import InNews from './InNews'

const Company = () => {
  return (
    <div className='position-relative'>
        <CompanySectionone/>
        <VestianSection/>
        <GlobalLeaderShip/>
        <Award/>
        <InNews/>
    </div>
  )
}

export default Company