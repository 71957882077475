import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import "./SideArrow.css";

const SideArrow = ({ isHovered }) => {
  return (
    <div
      className={`arrow-container p-1 ms-2 rounded-circle ${
        isHovered ? "arrow-hovered" : ""
      }`}
    >
      <FaArrowRight color="white" size={13} className="arrow arrow-white-mod" />
      <FaArrowRight color="black" size={13} className="arrow arrow-black-mod" />
    </div>
  );
};

export default SideArrow;
