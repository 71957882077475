import React from "react";
import IndustriesHeroComp from "../Component/IndustriesHeroComp/IndustriesHeroComp";
import SecondComponent from "../Component/SecondComponent/SecondComponent";
import ThirdCompnent from "../Component/ThirdCompnent/ThirdCompnent";
import RelatedArticle from "../../Services/Component/RelatedArticle/RelatedArticle";

const officeData = {
  Textinjsx: "Office",
  industriesView: [
    "View All Industries",
    "People working in a modern tech office on their laptops.",
  ],
  img: "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c659367d5957821afcd232_Technology.avif",
  leftText: "Elevate your workplace to empower your business.",
  description: `The modern office is more than just a place to work—it's a tool for attracting talent, fostering collaboration, and driving productivity. We partner with businesses to create office spaces that reflect your culture and support your goals. From flexible layouts that adapt to changing needs to tech-enabled environments that boost efficiency, we'll help you design, acquire, and manage office spaces that give your team the edge in today's competitive landscape.`,
  services: [
    {
      title: "Workplace Strategy & Occupancy Planning",
      details:
        "Transform your office into a strategic asset. We create flexible, efficient workspaces that enhance productivity, foster collaboration, and adapt to your organization's changing needs. Our data-driven approach ensures your office space supports your business objectives and employee well-being.",
    },
    {
      title: "Portfolio Strategy & Optimization",
      details:
        "Maximize the value and efficiency of your office portfolio. We align your real estate footprint with your business strategy, optimizing space utilization and costs across multiple locations. Our solutions support your growth, enhance operational flexibility, and improve your bottom line.",
    },
    {
      title: "Transaction Management",
      details:
        "Navigate office leasing with confidence. We provide objective guidance and strong negotiation support to secure favorable terms that prioritize your business interests. Our comprehensive market analysis and lease structuring expertise ensure flexibility and cost-effectiveness for your office space needs.",
    },
  ],
};

const Office = () => {
  return (
    <>
      <IndustriesHeroComp
        Textinjsx={officeData.Textinjsx}
        img={officeData.img}
      />
      <SecondComponent
        leftText={officeData.leftText}
        rightText={officeData.description}
      />
      <ThirdCompnent
        heading={"Office Real Estate Solutions Tailored to Your Business Goals"}
        sections={officeData.services}
      />
      <RelatedArticle articles={officeData.industriesView} />
    </>
  );
};

export default Office;
