import React from 'react'
import IndustriesHeroComp from '../Component/IndustriesHeroComp/IndustriesHeroComp'
import SecondComponent from '../Component/SecondComponent/SecondComponent'
import ThirdCompnent from '../Component/ThirdCompnent/ThirdCompnent'
import RelatedArticle from '../../Services/Component/RelatedArticle/RelatedArticle'

const industrialData = {
  Textinjsx: "Industrial",
  industriesView: ["View All Industries", "A forklift driver in a warehouse"],
  img: "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c6add30eecdeda7689b23d_Industrial.avif",
  leftText:
    "Engineer your facilities for peak performance and future readiness",
  description: `Your industrial spaces are the engines of your business, driving productivity and innovation. 
    We partner with industrial businesses of all sizes to create real estate solutions that maximize efficiency, 
    adaptability, and growth potential. From state-of-the-art production facilities to high-tech distribution centers, 
    we'll help you develop an industrial portfolio that streamlines your operations, enhances sustainability, 
    and positions your business to meet the demands of Industry 4.0 and beyond.`,
};
const sections = [
  {
    title: "Portfolio Strategy & Optimization",
    details: `Maximize the value of your industrial real estate portfolio. We align your network of facilities with 
        your business objectives, optimizing space utilization, reducing costs, and supporting your evolving distribution 
        and production needs across multiple locations.`,
  },
  {
    title: "Site Selection & Feasibility",
    details: `Identify strategic locations for your industrial facilities. We analyze crucial factors such as 
        transportation networks, labor markets, and zoning regulations to find optimal sites that enhance your supply 
        chain efficiency and operational performance.`,
  },
  {
    title: "Transaction Management",
    details: `Navigate complex industrial real estate transactions with confidence. Whether you're expanding your 
        distribution network or consolidating operations, we provide market insights and strong negotiation support 
        to secure flexible agreements that put your business interests first.`,
  },
];

export const Industrial = () => {
    return (
      <>
        <IndustriesHeroComp
          Textinjsx={industrialData.Textinjsx}
          img={industrialData.img}
        />
        <SecondComponent
          leftText={industrialData.leftText}
          rightText={industrialData.description}
        />
        <ThirdCompnent 
        heading={"Industrial Real Estate Solutions Tailored to Your Business Goals"}
        sections={sections} />                                                                                                                                                                                                                       
        <RelatedArticle articles={industrialData.industriesView} />
      </>
    );
}
