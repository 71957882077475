import React from "react";
import { FaArrowDownLong } from "react-icons/fa6";
import "./DropdownIcon.css";

const DropdownIcon = ({ isHovered }) => {
  return (
    <div
      className={`naaaavdropdownicon p-1 ms-2 rounded-circle ${
        isHovered ? "hovered" : ""
      }`}
    >
      <FaArrowDownLong color="white" size={13} className="arrow arrow-white" />
      <FaArrowDownLong color="black" size={13} className="arrow arrow-black" />
    </div>
  );
};

export default DropdownIcon;
