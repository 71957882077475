import React from "react";
import IndustriesHeroComp from "../Component/IndustriesHeroComp/IndustriesHeroComp";
import SecondComponent from "../Component/SecondComponent/SecondComponent";
import ThirdCompnent from "../Component/ThirdCompnent/ThirdCompnent";
import IndustriesClientComponent from "../Component/IndustriesClientComponent/IndustriesClientComponent";
import RelatedArticle from "../../Services/Component/RelatedArticle/RelatedArticle";

const retailData = {
  Textinjsx: "Retail",
  industriesView: [
    "View All Industries",
    "A cashier helping a customer at the checkout counter, while a line of customers wait patiently.",
  ],
  img: "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c633e496b526f9b64d8910_Retail_Industry.avif",
  leftText: "Transform your retail real estate into a business advantage.",
  description: `Your physical locations are more than just places to sell—they're opportunities to engage and inspire. 
  We partner with retailers of all sizes to create strategic real estate solutions that boost foot traffic, enhance customer 
  experiences, and drive sales. From prime locations to innovative store designs, we'll help you build a retail portfolio 
  that adapts to changing consumer behaviors and positions your brand for long-term success.`,
  services: [
    {
      title: "Portfolio Strategy & Optimization",
      details: `Maximize the value of your retail footprint. We align your store network with evolving consumer behaviors 
      and market trends, optimizing locations to enhance performance and support your omnichannel strategy.`,
    },
    {
      title: "Site Selection & Feasibility",
      details: `Identify prime locations for your retail expansion. Our data-driven approach analyzes foot traffic, demographics, 
      and market potential to secure sites that drive sales and strengthen your brand presence.`,
    },
    {
      title: "Lease Advisory & Restructuring",
      details: `Navigate complex retail lease negotiations with confidence. We secure flexible agreements that protect your 
      interests and adapt to changing market conditions, whether you're entering new markets or optimizing existing locations.`,
    },
    {
      title: "Retail Disposition Services",
      details: `Our disposition services for retail businesses focus on maximizing the value of underperforming locations, 
      repurposing spaces for alternative uses, and strategically realigning your real estate portfolio to support your evolving 
      business model.`,
    },
  ],
};

const RentalPage = () => {
  return (
    <div>
      <IndustriesHeroComp
        Textinjsx={retailData.Textinjsx}
        img={retailData.img}
      />

      <SecondComponent
        leftText={retailData.leftText}
        rightText={retailData.description}
      />

      <ThirdCompnent
        heading="Retail Real Estate Solutions Tailored to Your Needs"
        sections={retailData.services}
      />

      <IndustriesClientComponent />

      <RelatedArticle articles={retailData.industriesView} />
    </div>
  );
};

export default RentalPage;
