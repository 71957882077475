import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import "../style.css"

const ServicesHeroSection = ({ Textinjsx, img }) => {
  return (
    <div className="PortfolioHero-wrapper position-relative overflow-hidden">
      <div className="PortfolioHero-background"></div>
      <Container className="PortfolioHero-container py-5">
        <Row className="align-items-center">
          <Col lg={6} className="PortfolioHero-content py-5">
            <span className="PortfolioHero-label text-uppercase mb-4 fw-semibold d-block">Services</span>
            <h1 className="PortfolioHero-title display-1 fw-bold mb-5">
              {Textinjsx || <>Portfolio<br />Solutions</>}
            </h1>
            <Link to="/services" className="PortfolioHero-link d-inline-flex align-items-center gap-2">
              <BsArrowLeft className="PortfolioHero-arrow" />
              <span>View All Services</span>
            </Link>
          </Col>
          <Col lg={6} className="PortfolioHero-image-col position-relative d-none d-lg-block">
            <div style={{width:"100%"}} className='ratio ratio-1x1'>
              <Image
                src={img || "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c7ac2a52ac84ca0be3c77b_Portfolio%20SolutionsBW.avif"}
                alt="Portfolio Solutions Meeting"
                className="object-fit-cover mt-auto"
                priority
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServicesHeroSection;
