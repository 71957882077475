'use client'

import { useState } from 'react'
import { Container, Row, Col, Pagination } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const jobsData = [
  {
    id: 1,
    title: "Assistant Manager, Transactions",
    department: "Transaction Management",
    location: "Bangalore",
  },
  {
    id: 2,
    title: "Associate Broker",
    department: "Transaction Management",
    location: "Cleveland, OH",
  },
  {
    id: 3,
    title: "Associate Broker",
    department: "Transaction Management",
    location: "Fairfield, CT",
  },
  {
    id: 4,
    title: "Associate Broker",
    department: "Transaction Management",
    location: "Los Angeles, CA",
  },
  {
    id: 5,
    title: "Associate Broker",
    department: "Transaction Management",
    location: "Miami, FL",
  },
  {
    id: 6,
    title: "Associate Broker",
    department: "Transaction Management",
    location: "New York, NY",
  },
  {
    id: 7,
    title: "Associate Broker",
    department: "Transaction Management",
    location: "Phoenix, AZ",
  },
  {
    id: 8,
    title: "Associate Broker",
    department: "Transaction Management",
    location: "Denver, CO",
  },
  {
    id: 9,
    title: "Associate Broker",
    department: "Transaction Management",
    location: "Detroit, MI",
  },
  {
    id: 10,
    title: "Associate Broker",
    department: "Transaction Management",
    location: "Chicago, IL",
  },
  {
    id: 11,
    title: "Business Development Lead",
    department: "Sales",
    location: "Bangalore",
  },
  {
    id: 12,
    title: "Business Development Lead",
    department: "Sales",
    location: "Accounts • Bangalore",
  },
  {
    id: 13,
    title: "Brokerage Internship",
    department: "Transaction Management",
    location: "New York, NY",
  },
  {
    id: 14,
    title: "Brokerage Internship",
    department: "Transaction Management",
    location: "Charlotte, NC",
  },
  {
    id: 15,
    title: "Director, Brokerage",
    department: "Transaction Management",
    location: "Dallas, TX",
  },
  {
    id: 16,
    title: "Director, Brokerage",
    department: "Transaction Management",
    location: "New York, NY",
  },
  {
    id: 17,
    title: "Director, Brokerage",
    department: "Transaction Management",
    location: "Denver, CO",
  },
  {
    id: 18,
    title: "Director, Brokerage",
    department: "Transaction Management",
    location: "Cleveland, OH",
  },
  {
    id: 19,
    title: "Director, Brokerage",
    department: "Transaction Management",
    location: "Charlotte, NC",
  },
  {
    id: 20,
    title: "Director, Brokerage",
    department: "Transaction Management",
    location: "San Francisco, CA",
  },
  {
    id: 21,
    title: "Director, Brokerage",
    department: "Transaction Management",
    location: "Miami, FL",
  },
  {
    id: 22,
    title: "Director, Brokerage",
    department: "Transaction Management",
    location: "Tampa, FL",
  },
  {
    id: 23,
    title: "Director, Brokerage",
    department: "Transaction Management",
    location: "Austin, TX",
  },
  {
    id: 24,
    title: "Director Lease Administration",
    department: "Transaction Management",
    location: "Chicago, IL",
  },
  {
    id: 25,
    title: "Draftsman",
    department: "Design & Build",
    location: "Bangalore",
  },
  {
    id: 26,
    title: "Senior Architect",
    department: "Design & Build",
    location: "Bangalore",
  },
  {
    id: 27,
    title: "Project Coordinator",
    department: "Design & Build",
    location: "Bangalore",
  },
  {
    id: 28,
    title: "HR Executive",
    department: "Human Resources",
    location: "Kolkata • Talent Acquisition",
  },
  {
    id: 29,
    title: "HR Executive",
    department: "Human Resources",
    location: "Kolkata • Talent Acquisition",
  },
  {
    id: 30,
    title: "Finance Manager",
    department: "Finance",
    location: "Mumbai • Financial Operations",
  },
];


const itemsPerPage = 5

const JobItem = ({ title, department, location }) => (
    <Row className="CareersSectionTwo-job-item mb-3 py-3 border-bottom border-secondary align-items-center">
        <Col xs={12} md={8}>
            <h3 className="CareersSectionTwo-job-title h5 my-1">{title}</h3>
            <div className="CareersSectionTwo-job-details my-2">
                {location}
            </div>
        </Col>
        <Col xs={12} md={4} className="text-md-end mt-2 mt-md-0">
            <button variant="outline-light" size="sm" className="CareersSectionTwo-apply-btn">
                Apply Now
            </button>
        </Col>
    </Row>
)

const PaginationComponent = ({ totalPages, activePage, onPageChange }) => (
    <div className='d-flex justify-content-between w-100 mt-5 align-items-center'>
        {/* if current page is 1 then disable prev button invisible */}
        <button onClick={() => {
            if (activePage === 1) {
                return
            }
            onPageChange(activePage - 1)
        }} style={{ visibility: activePage === 1 ? 'hidden' : 'visible' }} className=' CareersSectionTwo-pagination-prev-button'>Prev</button>
        <Pagination className="CareersSectionTwo-pagination-list m-auto">
            {Array.from({ length: totalPages }).map((_, index) => (
                <Pagination.Item
                    key={index + 1}
                    active={index + 1 === activePage}
                    onClick={() => onPageChange(index + 1)}
                    className="CareersSectionTwo-pagination-item"
                >
                    {index + 1}
                </Pagination.Item>
            ))}
        </Pagination>
        <button onClick={() => {
            if (activePage === totalPages) {
                return
            }
            onPageChange(activePage + 1)
        }} style={{ visibility: activePage === totalPages ? 'hidden' : 'visible' }} className='CareersSectionTwo-pagination-next-button'>Next</button>
    </div>
)

export default function CareersSectionTwo() {
    const [activePage, setActivePage] = useState(1)
    const totalPages = Math.ceil(jobsData.length / itemsPerPage)
    const startIndex = (activePage - 1) * itemsPerPage
    const currentJobs = jobsData.slice(startIndex, startIndex + itemsPerPage)

    return (
        <div className="CareersSectionTwo-wrapper bg-dark text-white py-5">
            <Container>
                <h2 className="CareersSectionTwo-heading mb-5">Open Roles</h2>

                <div className="CareersSectionTwo-jobs">
                    {currentJobs.map((job) => (
                        <JobItem
                            key={job.id}
                            title={job.title}
                            department={job.department}
                            location={job.location}
                        />
                    ))}
                </div>

                <div className="CareersSectionTwo-pagination d-flex justify-content-center mt-4">
                    <PaginationComponent
                        totalPages={totalPages}
                        activePage={activePage}
                        onPageChange={setActivePage}
                    />
                </div>
            </Container>
        </div>
    )
}
