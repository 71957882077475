import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

export default function IndustriesClientComponent() {
    const IndustriesClientComponent = [
        {
            name: "Pep Boys",
            logo: "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66dc80be7ccb66556ed22057_image-292.avif"
        },
        {
            name: "Kirklands",
            logo: "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66dc80be7ccb66556ed22057_image-292.avif"
        },
        {
            name: "Fabio Viviani",
            logo: "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66dc80be7ccb66556ed22057_image-292.avif"
        },
        {
            name: "Panera",
            logo: "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66dc80be7ccb66556ed22057_image-292.avif"
        },
        {
            name: "PetSense",
            logo: "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66dc80be7ccb66556ed22057_image-292.avif"
        },
        {
            name: "Tint World",
            logo: "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66dc80be7ccb66556ed22057_image-292.avif"
        }
    ];

    return (
        <Container className="px-4 py-5">
            <Row className="gx-5">
                <Col lg={6}>
                    <div className="IndustriesClientComponent-content">
                        <div className="IndustriesClientComponent-label">
                            <div className="blue-square"></div>
                            <span>IndustriesClientComponent</span>
                        </div>
                        <h2 className="IndustriesClientComponent-heading">
                            We partner with leading retailers to maximize the business potential of their real estate.
                        </h2>
                        <p className="IndustriesClientComponent-description">
                            From big-box stores to specialty shops, we deliver personalized end-to-end solutions that align real estate strategy with business goals.
                        </p>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="IndustriesClientComponent-iconbox">
                        {IndustriesClientComponent.map((client, index) => (
                            <Image
                                key={index}
                                style={{
                                    aspectRatio: "1/1"
                                }}
                                src={client.logo}
                                alt={`${client.name} logo`}
                                className="IndustriesClientComponentclient-logo"
                                fluid
                            />
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

