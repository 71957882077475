import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import Service from "../Service/Service";
// import Project from "../Project/Project";
// import AboutUs from "../AboutUs/AboutUs";
import MainNavBar from "../GlobalComponent/MainNavBar.js/MainNavBar";
import Footer from "../GlobalComponent/Footer/Footer";
import PortfolioSolutions from "../Services/Portfoliosolutions/PortfolioSolutions";
import Transaction from "../Services/transaction/Transaction";
import Facility from "../Services/Facility/Facility";
import AllServices from "../Services/AllServices/AllServices";
import Office from "../industries/Office/Office";
import RentalPage from "../industries/RentalPage/RentalPage";
import HealgthCare from "../industries/HealgthCare/HealgthCare";
import { Industrial } from "../industries/Industrial/Industrial";
import IndustryAllService from "../Services/IndustryAllService/IndustryAllService";
import Company from "../Company/Company";
import Careers from "../Careers/Careers";
import ContactUs from "../ContactUs/ContactUs";
const RootRouter = () => {
  return (
    <>
      <MainNavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/service" element={<Service />} />
        <Route path="/company" element={<Company />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/services">
          <Route path="" element={<AllServices />} />
          <Route path="portfoliosolutions" element={<PortfolioSolutions />} />
          <Route path="transaction" element={<Transaction />} />
          <Route path="facility" element={<Facility />} />
          {/* <Route path="all" element={<AllServices />} /> */}
        </Route>
        <Route path="/industries">
          <Route path="" element={<IndustryAllService />} />
          <Route path="office" element={<Office />} />
          <Route path="retail" element={<RentalPage />} />
          <Route path="healthcare" element={<HealgthCare />} />
          <Route path="industrial" element={<Industrial />} />
        </Route>
      </Routes>
      <Footer />
    </>
  );
};

export default RootRouter;
