import React from "react";
import ServicesHeroSection from "../Component/ServicesHeroSection/ServicesHeroSection";
import AccordationComp from "../Component/AccordationComp/AccordationComp";
import SuccessStories from "../Component/SussessStories/SuccessStories";
import RelatedArticle from "../Component/RelatedArticle/RelatedArticle";

const Transaction = () => {
  const AccordationData = [
    {
      header: "Lease Advisory & Restructuring",
      text: "Optimize your lease agreements for better business outcomes. We analyze your current leases and market conditions to negotiate terms that support your operational and financial goals.",
      list: [
        "Comprehensive lease analysis and benchmarking.",
        "Rent review negotiations.",
        "Lease restructuring and renegotiation.",
        "Lease exit and renewal strategies.",
        "Portfolio optimization for leased properties.",
      ],
    },
    {
      header: "Integrated Transaction Management",
      text: "Our portfolio-wide approach to transactions ensures that every deal, from site selection to lease negotiation, aligns with your overarching real estate strategy. We leverage our global expertise and local market knowledge to secure terms that support your business objectives across your entire portfolio.",
      list: [
        "Portfolio-wide lease analysis and strategy development.",
        "Multi-location optimization and rationalization.",
        "Strategic acquisition and disposition planning.",
        "Sale-leaseback strategies for portfolio optimization.",
        "Lease administration and critical date management across all locations.",
      ],
    },
    {
      header: "Workspace Portfolio Planning & Delivery",
      text: "We seamlessly integrate workspace planning, design, and project management across your entire portfolio. Our holistic approach ensures consistency in workplace strategy while allowing for local customization, creating environments that enhance productivity and reflect your brand identity across all locations.",
      list: [
        "Portfolio-wide workplace strategy development.",
        "Standardized design guidelines with local adaptability.",
        "Multi-site project planning and coordination.",
        "Change management strategies for portfolio-wide initiatives.",
        "Integration of construction and relocation management across locations.",
      ],
    },
    {
      header: "Portfolio Performance Analytics",
      text: "Leveraging our Vportal platform, we provide a single source of truth for all your portfolio and project management needs. Our data-driven insights enable real-time decision-making, identify trends across your portfolio, and uncover opportunities for improvement and cost savings.",
      list: [
        "Centralized data management and reporting through Vportal.",
        "Custom KPI tracking and benchmarking across the portfolio.",
        "Predictive analytics for portfolio performance optimization.",
        "Real-time project tracking and portfolio-wide progress reporting.",
        "Integration of financial, operational, and occupancy data for comprehensive analysis.",
      ],
    },
  ];

  const accordationText = {
    leftText: "Every transaction is an opportunity.",
    rightText:
      "Maximize the value of your real estate with objective guidance and strong negotiation support for transactions that prioritize your best business interests. We analyze comprehensive market data, expose hidden lease terms, and secure flexible agreements that put you first.",
  };

  return (
    <div>
      <ServicesHeroSection
        Textinjsx={
          <>
            Transaction
            <br />
            Management
          </>
        }
        img="https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66de0939af86ab1c7bd2d5c2_Transaction%20Management%20BW.avif"
      />
      <AccordationComp
        leftText={accordationText.leftText}
        rightText={accordationText.rightText}
        AccordationContent={AccordationData}
      />
      <SuccessStories />
      <RelatedArticle />
    </div>
  );
};

export default Transaction;
