import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

const VestianSection = () => {
  return (
    <Container className="py-5">
      <Row
        className="vestian-row align-items-center flex-column-reverse flex-lg-row"
      >
        <Col lg={6} className="vestian-text">
          <h2 className="vestian-headline">
            At Vestian, we specialize in end-to-end commercial real estate solutions exclusively for business.
          </h2>
          <p className="vestian-description">
            Our integrated approach seamlessly blends strategic planning with meticulous execution, providing a comprehensive suite of services under one roof. From optimizing global portfolios to designing workspaces that boost productivity, we craft bespoke strategies that not only meet but exceed your business expectations. By leveraging cutting-edge technology and market intelligence, we anticipate trends and mitigate risks, ensuring your real estate strategy remains agile and future-proof. With Vestian as your partner, you're not just managing properties; you're unleashing the full potential of your real estate to drive growth, efficiency, and innovation across your entire organization.
          </p>
        </Col>
        <Col lg={6} className="vestian-image">
          <Image
            src="https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c762670a11d1ee5c06a152_Office-p-1080.avif"
            alt="Office team working"
            fluid
            className="vestian-team-image"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default VestianSection;
