import { Col, Container, Row } from "react-bootstrap"
import "../style.css"
import React from 'react'

const SecondComponent = ({leftText, rightText}) => {
  return (
    <div style={{ backgroundColor: "#EFF0F2" }} className="py-5">
    <Container  className="industriessecondComp-container py-5">
      <Row className="industriessecondComp-row justify-content-between gx-5">
        <Col md={6} className="industriessecondComp-title-col">
          <h1 className="industriessecondComp-title display-4 fw-bold mb-4" style={{ fontSize: '3.5rem' }}>
            {leftText || "Your turn-key real estate solution"}
          </h1>
        </Col>
        <Col md={6} className="industriessecondComp-description-col">
          <p className="industriessecondComp-description lead mb-5">
            {rightText || "From lease negotiation to big picture thinking and long-term strategic guidance, we personalize our process to meet your needs. Think of us as an extension of your team, matching your objectives with market opportunities to put your real estate to work."}
          </p>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default SecondComponent