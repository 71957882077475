import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";

const ContactUsForm = () => {
    const options = [
        { value: "general", label: "General Inquiry" },
        { value: "support", label: "Support" },
        { value: "sales", label: "Sales" },
        { value: "feedback", label: "Feedback" },
    ];

    return (
        <Form>
            <Row>
                <Col md={6} className="mb-3">
                    <Form.Group controlId="firstName">
                        {/* <Form.Label>First Name</Form.Label> */}
                        <Form.Control
                            type="text"
                            placeholder="First Name"
                            className="ContactUsForm-input"
                        />
                    </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                    <Form.Group controlId="lastName">
                        {/* <Form.Label>Last Name</Form.Label> */}
                        <Form.Control
                            type="text"
                            placeholder="Last Name"
                            className="ContactUsForm-input"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group controlId="email" className="mb-3">
                {/* <Form.Label>Your Email</Form.Label> */}
                <Form.Control
                    type="email"
                    placeholder="Your Email"
                    className="ContactUsForm-input"
                />
            </Form.Group>
            <Form.Group controlId="companyName" className="mb-3">
                {/* <Form.Label>Company Name</Form.Label> */}
                <Form.Control
                    type="text"
                    placeholder="Company Name"
                    className="ContactUsForm-input"
                />
            </Form.Group>
            <Form.Group controlId="phoneNumber" className="mb-3">
                {/* <Form.Label>Phone Number (Optional)</Form.Label> */}
                <Form.Control
                    type="tel"
                    placeholder="Phone Number (Optional)"
                    className="ContactUsForm-input"
                />
            </Form.Group>
            <Form.Group controlId="subject" className="mb-3">
                <Form.Label style={{
                    width: "content",
                }}>What do you need?</Form.Label>
                <Select
                    options={options}
                    placeholder="Pick a subject..."
                    isSearchable
                    className="ContactUsForm-select  w-100"
                    classNamePrefix="ContactUsForm"
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            backgroundColor: 'transparent',
                            border: 'none',
                            borderBottom: '2px solid #ccc', // Bottom border
                            boxShadow: 'none',
                            borderRadius: '0',
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            color: 'rgb(225, 225, 225)', // Placeholder color
                        }),
                        dropdownIndicator: (provided) => ({
                            ...provided,
                            color: 'rgb(225, 225, 225)', // Arrow color
                        }),
                        indicatorSeparator: (provided) => ({
                            ...provided,
                            display: 'none', // Hide the separator line
                        }),
                        menu: (provided) => ({
                            ...provided,
                            backgroundColor: '#333', // Darker grey background for options
                            borderRadius: '0.25rem',
                            color: 'white', // White text for the options
                        }),
                        menuList: (provided) => ({
                            ...provided,
                            padding: '0', // Remove padding for better control
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected ? '#555' : '#333', // Dark grey for selected option
                            color: 'white', // White text for options
                            border: '1px solid #fff', // White border for options
                            cursor: 'pointer',
                            ':hover': {
                                backgroundColor: '#444', // Lighter grey on hover
                            },
                        }),
                    }}
                />

            </Form.Group>
            <Form.Group controlId="message" className="mb-3">
                {/* <Form.Label>Message</Form.Label> */}
                <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Message"
                    className="ContactUsForm-input"
                />
            </Form.Group>
            <Form.Text className="text-white mb-3 d-block">
                Submitting this form confirms your express consent to receive electronic communications. These communications consist of educational materials, opportunities for online events, and corporate information. You may withdraw your consent by unsubscribing at any time.
            </Form.Text>
            <Button variant="primary" type="submit" className="p-3">
                Send Message
            </Button>
        </Form>
    );
};

export default ContactUsForm;
