import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { BsArrowRight } from 'react-icons/bs'
const defaultstories = [
  {
    title:
      "Maintaining Budget Discipline in Changing Conditions for a Global IT Leader",
    sector: "SOFTWARE & TECHNOLOGY",
    year: "2023",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66db0831156f274becc5546b_Global%20IT%20Leader_Case%20Study_thumbnail-p-500.avif",
  },
  {
    title: "Tech Expansion: Navigating China's Market",
    sector: "SOFTWARE & TECHNOLOGY",
    year: "2023",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66daf613dd0f55d13a4ad92d_Mobileye_Case%20Study_thumbnail-p-500.avif",
  },
  {
    title:
      "Sweetening Success: Vestian Delivers Rapid Retail Refresh for Fannie May",
    sector: "RETAIL",
    year: "2023",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66db0b3cd7f0afdc6379a039_Fannie%20May_interior_Case%20Study_thumbnail-min-p-1080.avif",
  },
];
const SuccessStories = ({stories=defaultstories}) => {
  return (
    <Container className="SuccessStories-container py-5">
        <h1 className="SuccessStories-title display-4 mb-5">Success Stories</h1>

        <Row className="SuccessStories-row g-4">
          {stories.map((story, index) => (
            <Col key={index} lg={4} md={6} className="SuccessStories-col">
              <Card className="SuccessStories-card border-0 h-100 position-relative overflow-hidden">
                <div className="SuccessStories-image-wrapper" style={{ height: '520px' }}>
                  <Card.Img
                    variant="top"
                    src={story.image}
                    className="SuccessStories-image h-100 w-100 object-fit-cover"
                  />
                </div>
                <Card.Body className={`SuccessStories-card-overlay position-absolute w-100 p-4 bg-white m-4 ${index === 1 ? 'start-0 top-0' : 'bottom-0 start-0'
                  }`}
                  style={{ maxWidth: 'calc(100% - 2rem)' }}>
                  <div className="SuccessStories-card-content">
                    <div className="SuccessStories-card-header d-flex justify-content-between align-items-start mb-4">
                      <h3 className="SuccessStories-card-title h5 mb-0 pe-4">{story.title}</h3>
                      <BsArrowRight className="SuccessStories-arrow ms-2 flex-shrink-0" size={24} />
                    </div>
                    <div className="SuccessStories-metadata d-flex justify-content-between text-uppercase">
                      <div className="SuccessStories-sector">
                        <small className="text-muted d-block mb-1">SECTOR</small>
                        <span className="fw-medium">{story.sector}</span>
                      </div>
                      <div className="SuccessStories-year">
                        <small className="text-muted d-block mb-1">YEAR</small>
                        <span className="fw-medium">{story.year}</span>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
  )
}

export default SuccessStories