import React from "react";
import { Container } from "react-bootstrap";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import "react-multi-carousel/lib/styles.css";
import "./Style.css";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";

const defaultarticles = [
  {
    category: "NEWS",
    date: "18 SEP",
    title: "Vestian Secures Master Services Agreement with Tint World",
    description:
      "Vestian, a global corporate real estate solutions provider, has entered into a Master Services Agreement with Tint World, a leading automotive styling franchise.",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66eae6b8040b29efa4a24954_tintworld_logo_full_black_usa_horizontal.avif",
  },
  {
    category: "NEWS",
    date: "18 SEP",
    title:
      "Real estate industry hopes lower borrowing costs will bring better times",
    description:
      "The Federal Reserve's rate reduction is intended to invigorate the real estate market, yet office spaces continue to face challenges from high vacancies and the evolving dynamics of remote work.",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/6716a1ea70e854651ed55289_costar-group-logo.png",
  },
  {
    category: "NEWS",
    date: "4 JUN",
    title:
      "Election 2024 Results: Continuity or change? Corporate India speaks on the 2024 poll results",
    description:
      "Industry leaders and economists said that while India’s economy is projected to grow at 6.8 per cent in FY25, surpassing other major global economies, all eyes are on the new government.",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f19dc3283d452d5600715e_Financial%20Express%20Logo.svg",
  },
  {
    category: "NEWS",
    date: "29 APR",
    title:
      "Office absorption touches 134 lakh sq ft in Q1 2024; up by 13% year-on-year: report",
    description:
      "The office space leasing in Delhi-NCR fell 25% annually to 18.1 lakh sq ft in January-March this year on lower demand from corporates, according to the report.",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f19de1404b49039b3d4266_Hindustan%20Times%20Logo_.svg",
  },
  {
    category: "NEWS",
    date: "15 APR",
    title:
      "Foreign funds avoid real estate; invest only $11 mn in Jan-Mar: Vestian",
    description:
      "Institutional investments in real estate declined 55 per cent annually in January-March this year to USD 552 million as cautious foreign investors stayed away, pumping in only USD 11 mn.",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f46e933f6e399a03e7bd7a_Business%20Standard%20Logo.png",
  },
  {
    category: "NEWS",
    date: "10 MAR",
    title:
      "SEBI notifies SM REITs: Move to regulate fractional ownership industry and safeguard investors’ interests",
    description:
      "The recent decision by SEBI is expected to have a positive impact on the emerging fractional ownership of both residential and commercial assets, say experts.",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f19de1404b49039b3d4266_Hindustan%20Times%20Logo_.svg",
  },
  {
    category: "NEWS",
    date: "12 JAN",
    title:
      "Institutional investments from domestic investors in realty sector up 120% in 2023 to $1.5 billion",
    description:
      "The inflow of funds from domestic investors, Vestian report said, stood at $1511 million ($1.5 billion) last year, registering a growth of 120 per cent from $687 million in the 2022 calendar year.",
    image:
      "https://cdn.prod.website-files.com/6650989eef5b5b85680d0708/66f19dc3283d452d5600715e_Financial%20Express%20Logo.svg",
  },
];

const InNews = ({ articles = defaultarticles }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const CustomButtonGroup = ({ next, previous }) => {
    return (
      <div className="PortfolioCarousel-buttons position-absolute bottom-0 start-0 d-flex gap-2 mb-4">
        <button
          className="PortfolioCarousel-button"
          onClick={previous}
          aria-label="Previous slide"
        >
          <BsArrowLeft size={20} />
        </button>
        <button
          className="PortfolioCarousel-button"
          onClick={next}
          aria-label="Next slide"
        >
          <BsArrowRight size={20} />
        </button>
      </div>
    );
  };

  return (
    <Container className="PortfolioCarousel-container py-5 position-relative">
      <h2 className="PortfolioCarousel-title mb-4">In the News</h2>
      <Carousel
        responsive={responsive}
        infinite={true}
        customButtonGroup={<CustomButtonGroup />}
        arrows={false}
        renderButtonGroupOutside={true}
        className="PortfolioCarousel-wrapper"
      >
        {articles.map((article, index) => (
          <div key={index} className="PortfolioCarousel-item px-3 mb-3">
            <div className="PortfolioCarousel-image-wrapper mb-4">
              <img
                src={article.image}
                alt={article.title}
                className="PortfolioCarousel-image w-100 h-100 object-fit-cover rounded-3"
              />
            </div>
            <div className="PortfolioCarousel-content">
              <div className="d-flex align-items-center gap-3 mb-3">
                <div
                  style={{
                    width: "14px",
                    height: "14px",
                    backgroundColor: "#0000FF",
                  }}
                ></div>
                <span className="PortfolioCarousel-badge">
                  {article.category}
                </span>
                <span className="PortfolioCarousel-date">{article.date}</span>
              </div>
              <h3 className="PortfolioCarousel-item-title h5 mb-3">
                {article.title}
              </h3>
              <p className="PortfolioCarousel-description text-muted">
                {article.description}
              </p>
              <Link
                href="/"
                className="PortfolioCarousel-readmore d-flex align-items-center text-black"
                style={{
                  textDecoration: "none",
                  fontSize: "16px",
                  color: "black",
                }}
              >
                <span>Read More</span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#000000",
                    marginLeft: "8px",
                  }}
                >
                  <BsArrowRight size={10} style={{ color: "white" }} />
                </div>
              </Link>
            </div>
          </div>
        ))}
      </Carousel>
    </Container>
  );
};

export default InNews;
