import React from "react";
import IndustriesHeroComp from "../Component/IndustriesHeroComp/IndustriesHeroComp";
import SecondComponent from "../Component/SecondComponent/SecondComponent";
import ThirdCompnent from "../Component/ThirdCompnent/ThirdCompnent";
import RelatedArticle from "../../Services/Component/RelatedArticle/RelatedArticle";

const healthcareData = {
  Textinjsx: "Healthcare",
  industriesView: [
    "View All Industries",
    "A team of nurses and doctors gathered around a nursing station, reviewing patient charts and discussing care plans.",
  ],
  img: "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c764041673dc501f0f2ee4_Healthcare_2.avif",
  leftText: "Transform your facilities into catalysts for better care",
  description: `In healthcare, your real estate directly impacts patient outcomes and operational efficiency. 
    We partner with hospitals, clinics, and care providers to develop facility solutions that enhance 
    patient experiences, support staff, and adapt to evolving medical technologies. From site selection 
    for new facilities to optimizing existing spaces, we'll help you create environments that promote 
    healing and advance your mission of care.`,
  services: [
    {
      title: "Portfolio Strategy & Optimization",
      details: `Align your healthcare real estate portfolio with your patient care objectives. 
        We optimize your network of facilities to enhance operational efficiency, improve 
        patient access, and support your evolving healthcare delivery models.`,
    },
    {
      title: "Site Selection & Feasibility",
      details: `Identify strategic locations for your healthcare facilities. Our data-driven 
        approach considers factors crucial to patient care, including demographics, accessibility, 
        and community health needs, ensuring your expansion plans maximize impact and efficiency.`,
    },
    {
      title: "Transaction Management",
      details: `Maximize the value of your healthcare real estate transactions. We provide objective 
        guidance and strong negotiation support, securing flexible agreements that adapt to the rapidly 
        changing healthcare landscape and prioritize your operational needs.`,
    },
  ],
};

const HealthCare = () => {
  return (
    <>
      <IndustriesHeroComp
        Textinjsx={healthcareData.Textinjsx}
        img={healthcareData.img}
      />

      <SecondComponent
        leftText={healthcareData.leftText}
        rightText={healthcareData.description}
      />

      <ThirdCompnent
        heading="Comprehensive Healthcare Real Estate Solutions"
        sections={healthcareData.services}
      />

      <RelatedArticle articles={healthcareData.industriesView} />
    </>
  );
};

export default HealthCare;
