import React from 'react'
import CareersSectionone from './CareersSectionone'
import CareersSectionTwo from './CareerSecctiontwo'
import "./style.css"

const Careers = () => {
  return (
    <div>
        <CareersSectionone/>
        <CareersSectionTwo/>
    </div>
  )
}

export default Careers