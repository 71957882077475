import React from "react";
import { Container } from "react-bootstrap";
import "./AllServices.css";
import AllServicesBoxComponent from "./AllServicesBoxComponent";

const AllServices = () => {
  const servicesData = [
    {
      title: "Portfolio Solutions",
      description:
        "From lease negotiation to big picture thinking and long-term strategic guidance, we personalize our process to meet your needs. Think of us as an extension of your team, matching your objectives with market opportunities to put your real estate to work.",
      services: [
        { title: "Portfolio Strategy & Optimization", link: "#" },
        { title: "Integrated Transaction Management", link: "#" },
        { title: "Workspace Portfolio Planning & Delivery", link: "#" },
        { title: "Portfolio Performance Analytics", link: "#" },
      ],
      imageSrc:
        "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c7ac2a52ac84ca0be3c77b_Portfolio%20SolutionsBW-p-1080.avif",
      imageAlt: "Services Image",
      btnText: "Learn More",
      btnLink: "#",
    },
    {
      title: "Advisory Services",
      description:
        "From market analysis to risk management, we approach every point on your real estate journey with strategic expertise to maximize your business potential. Our advisory services include: comprehensive market research, locally-informed and accurate valuations, incentive and labor market analysis, sustainable urban solutions, and data-driven investment guidance for a more robust portfolio.",
      services: [
        { title: "Market Research & Assessment", link: "#" },
        { title: "Urban Infrastructure Planning", link: "#" },
        { title: "Valuation Advisory", link: "#" },
        { title: "Investment Advisory", link: "#" },
        { title: "Incentives & Labor Analytics", link: "#" },
      ],
      imageSrc:
        "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c7ac1491fcb5d16aee54bb_Advisory%20Services_BW-p-800.avif",
      imageAlt: "Advisory Services Image",
      btnText: "Learn More",
      btnLink: "#",
    },
    {
      title: "Transaction Management",
      description:
        "Maximize the value of your real estate with objective guidance and strong negotiation support for transactions that prioritize your best business interests. We analyze comprehensive market data, expose hidden lease terms, and secure flexible agreements that put you first.",
      services: [
        { title: "Lease Advisory & Restructuring", link: "#" },
        { title: "Capital Markets", link: "#" },
        { title: "Site Selection & Feasibility", link: "#" },
        { title: "Lease Administration", link: "#" },
      ],
      imageSrc:
        "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66de0939af86ab1c7bd2d5c2_Transaction%20Management%20BW-p-1080.avif",
      imageAlt: "Transaction Management Image",
      btnText: "Learn More",
      btnLink: "#",
    },
    {
      title: "Design & Project Management",
      description:
        "We create spaces where business thrives through human-centered design, energy efficient infrastructure solutions, and smart integrated technology. Our workplaces blend functionality with modern design, creating environments that increase employee happiness. Plus, our global footprint means we can help you put down roots anywhere.",
      services: [
        { title: "Design Services", link: "#" },
        { title: "Project Management", link: "#" },
        { title: "Workplace Strategy", link: "#" },
        { title: "Cost Consultancy", link: "#" },
        { title: "Occupancy Planning", link: "#" },
      ],
      imageSrc:
        "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66de08aa2db2e90f98045ab6_Design%20%26%20Project%20Services_BW-p-1080.avif",
      imageAlt: "Design & Project Management Image",
      btnText: "Learn More",
      btnLink: "#",
    },
    {
      title: "Facility Management",
      description:
        "Optimize your facilities with our comprehensive management solutions. We take care of daily operations, maintenance, and sustainability efforts, allowing you to focus on your core business.",
      services: [
        { title: "Workplace Experience", link: "#" },
        { title: "Strategic Sourcing", link: "#" },
        { title: "Engineering & Technical Services", link: "#" },
        { title: "Sustainability Services", link: "#" },
      ],
      imageSrc:
        "https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/66c7ac24b7800d68fc50571c_Facility%20Management_BW.avif",
      imageAlt: "Facility Management Image",
      btnText: "Learn More",
      btnLink: "#",
    },
  ];

  return (
    <Container>
      <div
        style={{ minHeight: "100vh" }}
        className="d-flex justify-content-center align-items-start flex-column"
      >
        <div className="d-flex justify-content-center align-items-start">
          <div
            className="m-auto"
            style={{
              height: "11px",
              width: "11px",
              backgroundColor: "rgb(32, 77, 236)",
            }}
          ></div>
          <h6 className="m-auto ms-2">Services</h6>
        </div>
        <h3 className="AllServices-heading">
          Tailored to <br /> Transform
        </h3>
      </div>
      {servicesData.map((service, index) => (
        <AllServicesBoxComponent
          key={index}
          title={service.title}
          description={service.description}
          services={service.services}
          imageSrc={service.imageSrc}
          imageAlt={service.imageAlt}
          btnText={service.btnText}
          btnLink={service.btnLink}
          variant={index % 2 === 1 ? "alternative" : undefined}
        />
      ))}
    </Container>
  );
};

export default AllServices;
